html{
  height:100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', sans-serif;
  height:100%;
}

#root{
  height: 100%;
}